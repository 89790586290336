import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { useCallback, useEffect, useRef, useState } from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import { Text } from 'ui-kit-v2/text/text';

import Spinner from 'ui-kit/spinner/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { resetSmsVerification } from 'state/account/account.reducers';
import {
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountCheckSmsRequestsStatusRoutine,
    accountSendSmsConfirmationsRoutine
} from 'state/account/account.routines';
import {
    accountSmsCheckingStatusSelector,
    accountSmsRequestInitiatedSelector,
    accountSmsRequestsPendingSelector,
    accountSmsRequestsVerifiedSelector
} from 'state/account/account.selectors';

import { SMS_OPT_IN_TIMER, SMS_POLL_MAX_TIME_SECONDS } from 'const/account';
import { BREAKPOINTS } from 'const/breakpoints';

import { SmsTextNumbersToVerify } from 'types/sms';

import { addZero } from 'util/number';
import { maskPhoneNumber, removeChars } from 'util/string';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './mobile-phone-validation-setup.styles.scss';

const OnboardingMobilePhoneValidationSetupPage = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const intervalsRef = useRef<number[]>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phoneNumber = queryParams.get('phoneNumber') || '';

    const smsRequestInitiated = useSelector(accountSmsRequestInitiatedSelector);
    const checkingSmsStatus = useSelector(accountSmsCheckingStatusSelector);
    const smsRequestsPending = useSelector(accountSmsRequestsPendingSelector);
    const accountSmsRequestsVerified = useSelector(accountSmsRequestsVerifiedSelector);

    const [showResetButton, setShowResendButton] = useState(false);
    const [hasApiError, setHasApiError] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [smsRequestTime, setSmsRequestTime] = useState<number>(Date.now());
    const [isCountdownActive, setIsCountdownActive] = useState(false);

    const clearAllIntervals = () => {
        intervalsRef.current.forEach(clearInterval);
        intervalsRef.current = [];
    };

    const cancelSmsRequest = useCallback(
        ({ onSuccess }: { onSuccess: () => void }) => {
            dispatch(
                accountCancelSmsRequestRoutine.trigger({
                    onSuccess: () => {
                        dispatch(resetSmsVerification());
                        setIsResending(false);
                        onSuccess();
                    },
                    onFailure: () => {
                        setIsResending(false);
                    }
                })
            );
        },
        [dispatch]
    );

    const startPolling = useCallback(() => {
        const intervalId = window.setInterval(() => {
            if (!checkingSmsStatus && smsRequestsPending) {
                dispatch(
                    accountCheckSmsRequestsStatusRoutine.trigger({
                        onFailure: () => setHasApiError(true)
                    })
                );
            }
        }, SMS_POLL_MAX_TIME_SECONDS);

        intervalsRef.current.push(intervalId);
    }, [checkingSmsStatus, dispatch, smsRequestsPending]);

    const sendSms = useCallback(() => {
        setIsResending(true);
        setShowResendButton(false);
        clearAllIntervals();

        const phoneNumObj: SmsTextNumbersToVerify = {
            [removeChars(phoneNumber)]: ['RefillReminderText']
        };

        cancelSmsRequest({
            onSuccess: () => {
                dispatch(
                    accountSendSmsConfirmationsRoutine.trigger({
                        phoneNumbers: phoneNumObj,
                        onSuccess: () => {
                            dispatch(
                                accountCheckPendingSmsRequestsRoutine.trigger({
                                    onSuccess: () => {
                                        startPolling();
                                        setIsCountdownActive(true);
                                        setSmsRequestTime(Date.now());
                                        setIsResending(false);
                                    }
                                })
                            );
                        },
                        onFailure: () => {
                            setIsResending(false);
                            setHasApiError(true);
                        }
                    })
                );
            }
        });
    }, [cancelSmsRequest, dispatch, phoneNumber, startPolling]);

    useEffect(() => {
        if (accountSmsRequestsVerified) {
            // navigate to the next page
        } else if (!smsRequestInitiated || !phoneNumber) {
            navigate('/secure/onboarding/mobile-phone-setup');
        }
    }, [phoneNumber, accountSmsRequestsVerified, smsRequestInitiated, dispatch]);

    useEffect(() => {
        if (showResetButton) {
            clearAllIntervals();
        }
    }, [showResetButton]);

    useEffect(() => sendSms(), []);

    const renderCountDown = useCallback(
        ({ minutes, seconds, completed }: CountdownTimeDelta) => {
            setShowResendButton(false);

            if (completed) {
                setShowResendButton(true);
                clearAllIntervals();
            }

            return (
                <div className="mobile-phone-validation-setup-page__countdown__container">
                    {!isCountdownActive ? (
                        <Spinner isVisible={true} t={t} fullOverlay={false} />
                    ) : (
                        <>
                            <Text
                                size="num-4x"
                                weight={800}
                                lineHeigh={36}
                                color={completed ? 'silver' : 'dusk'}
                                className="mobile-phone-validation-setup-page__countdown__timer"
                            >
                                {minutes}:{addZero(seconds)}
                            </Text>
                            <Spinner isVisible={!completed} t={t} fullOverlay={false} />
                        </>
                    )}
                </div>
            );
        },
        [t, isCountdownActive]
    );

    return (
        <WorkflowLayout
            className="mobile-phone-validation-setup-page"
            metaData={{ nodeTitle: 'Mobile Phone Validation' }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                title={t('pages.onboarding.mobilePhoneValidationSetup.title')}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={5}
                        usage={4.6}
                        customColor="picton-blue"
                        handleClick={() => navigate('/secure/onboarding/mobile-phone-setup?phoneNumber=' + phoneNumber)}
                    />
                }
            >
                <Spinner isVisible={isResending} t={t} fullOverlay={true} />
                <div className="mobile-phone-validation-setup-page__container">
                    <div className="mobile-phone-validation-setup-page__container__information">
                        <Text size="num-2x" weight={500} lineHeigh={24} color="dusk">
                            {t('pages.onboarding.mobilePhoneValidationSetup.description_one', {
                                phoneNumber: maskPhoneNumber(phoneNumber)
                            })}
                        </Text>
                        <Text size="num-2x" weight={500} lineHeigh={24} color="dusk">
                            {t('pages.onboarding.mobilePhoneValidationSetup.description_two')}
                        </Text>
                    </div>

                    {hasApiError ? (
                        <div className="mobile-phone-validation-setup-page__countdown--error">
                            <Spinner isVisible={true} t={t} fullOverlay={false} />
                            <ToastBox
                                variant="error-v2"
                                icon="error-v2"
                                backgroundColor={width <= BREAKPOINTS.sm ? 'alice-blue-tint' : 'blush-pink'}
                            >
                                <Text size="num-1_75x">{t('pages.onboarding.mobilePhoneValidationSetup.trouble')}</Text>
                            </ToastBox>
                            <ButtonComponent
                                variant="ghost"
                                label={t('pages.onboarding.mobilePhoneValidationSetup.skip')}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="mobile-phone-validation-setup-page__countdown">
                                <Countdown
                                    key={smsRequestTime}
                                    renderer={renderCountDown}
                                    date={smsRequestTime + SMS_OPT_IN_TIMER}
                                />
                            </div>

                            <ToastBox icon="info" iconColor="cathams-blue">
                                <Text size="num-1_75x">{t('pages.onboarding.mobilePhoneValidationSetup.info')}</Text>
                            </ToastBox>

                            <ButtonComponent
                                isVisible={showResetButton}
                                variant="ghost"
                                label={t('pages.onboarding.mobilePhoneValidationSetup.resend')}
                                onClick={sendSms}
                            />
                        </>
                    )}
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};
export default OnboardingMobilePhoneValidationSetupPage;

export const query = graphql`
    query OnboardingMobilePhoneValidationSetupPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
