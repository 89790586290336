import { TextProps } from './text.props';
import './text.styles.scss';

const weightMap = {
    400: 'regular',
    500: 'regular',
    600: 'demi',
    700: 'demi',
    800: 'bold',
    900: 'extra-bold'
};

export const Text = ({
    as: Component = 'p',
    children,
    size = 'num-1x', // like our bootstrap variables, matches with figma
    color = 'ebony-clay', // like our bootstrap variables
    weight = 500, // 400, 500, 600, 700, 800, 900 (like figma)
    lineHeigh, // In PX (like figma)
    className
}: TextProps) => {
    const weightClass = weightMap[weight] || 'regular';

    const customClassName = `text-component text-component--${size} text-component--${color} text-component--${weightClass} ${
        className ?? ''
    }`;

    const customStyle = {
        lineHeight: lineHeigh ? pxToRem(lineHeigh) : '1.2rem'
    };

    return (
        <Component className={customClassName} style={customStyle}>
            {children}
        </Component>
    );
};

export const pxToRem = (px: number, baseFontSize = 16): string => {
    return `${px / baseFontSize}rem`;
};
